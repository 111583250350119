import React from "react";
import { Icon, List, ListItem } from "@mui/material";
import CopyrightIcon from "@mui/icons-material/Copyright";
import "./Footer.css";

const Footer = () => {
  return (
    <footer>
      <List className="container-items-footer">
        <ListItem className="item-footer">
          <CopyrightIcon />
          2024 - Thunder. Todos los derechos reservados
        </ListItem>
        <ListItem className="item-footer">Politicas de privacidad</ListItem>
        <ListItem className="item-footer">Terminos y condiciones</ListItem>
      </List>
    </footer>
  );
};

export default Footer;
