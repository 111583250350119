import React, { useState } from "react";
import { Switch, Typography } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import WbSunnyIcon from '@mui/icons-material/WbSunny';
import ModeNightIcon from '@mui/icons-material/ModeNight';

const Header = ({ toggleDarkMode, setToggleDarkMode }: any) => {
  // state to manage the dark mode

  // function to toggle the dark mode as true or false
  const toggleDarkTheme = () => {
    setToggleDarkMode(!toggleDarkMode);
  };

  // applying the primary and secondary theme colors

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static">
        <Toolbar>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            <img
              width="150"
              src="https://res.cloudinary.com/dw6c4dado/image/upload/v1703008492/encuentros-app/logos/Logo-thunder-violeta_hjkzjn.png"
            />
          </Typography>
          <Switch checked={toggleDarkMode} onChange={toggleDarkTheme} />
          {toggleDarkMode ? <WbSunnyIcon />  :  <ModeNightIcon />}
        </Toolbar>
      </AppBar>
    </Box>
  );
};
export default Header;
