import * as React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import { items } from '../../data/items';
import Item from './Item';

export default function ListItems() {
  return (
    <List style={{ 'padding' : '1em' }} sx={{ bgcolor: 'background.paper' }}>
        {items.map((item, index) => {
            return <Item item={item} key={index} />
        })}
    </List>
  );
}
