import React, { useState } from "react";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import {
  Card,
  CardContent,
  CardMedia,
  Switch,
  Typography,
} from "@mui/material";
import Header from "./components/header/Header";
import "./App.css";
import ListItems from "./components/items/List";
import GooglePlayButton from "./components/buttons/GooglePlayButton";
import Footer from "./components/footer/Footer";
import Social from "./components/buttons/Social";

export default function App() {
  const [toggleDarkMode, setToggleDarkMode] = useState(true);

  const darkTheme = createTheme({
    palette: {
      mode: toggleDarkMode ? "dark" : "light", // handle the dark mode state on toggle
      primary: {
        main: "#90caf9",
      },
      secondary: {
        main: "#131052",
      },
    },
  });

  return (
    <ThemeProvider theme={darkTheme}>
      <CssBaseline />
      <Header
        toggleDarkMode={toggleDarkMode}
        setToggleDarkMode={setToggleDarkMode}
      />
      <div className="title-container">
        <Typography className="first-title" variant="h3">Una App de Encuentros Casuales</Typography>
      </div>
      <div className="container-description">
        <Typography className="description" variant="h6">
          Descubre una nueva forma de socializar y conectarte con personas
          afines a ti. Con nuestra aplicación, encontrarás una amplia variedad
          de eventos y encuentros casuales diseñados para que puedas conocer
          gente nueva y crear conexiones significativas.
        </Typography>
      </div>
      <div className="container-images">
        <div className="image-and-title">
          <img
            className="images-header"
            src="https://res.cloudinary.com/dw6c4dado/image/upload/v1712586189/encuentros-app/landing-thunder/Sin_t%C3%ADtulo_500_x_900_px_4_jvfilm.png"
          />
          <Typography className="title" variant="body1">
            Busca tu categoria
          </Typography>
        </div>
        <div className="image-and-title">
          <img
            className="images-header"
            src="https://res.cloudinary.com/dw6c4dado/image/upload/v1712586055/encuentros-app/landing-thunder/Sin_t%C3%ADtulo_500_x_900_px_3_zzzj8d.png"
          />
          <Typography className="title" variant="body1">
            Encontra un evento
          </Typography>
        </div>
        <div className="image-and-title">
          <img
            className="images-header"
            src="https://res.cloudinary.com/dw6c4dado/image/upload/v1712585917/encuentros-app/landing-thunder/Sin_t%C3%ADtulo_500_x_900_px_2_b1nwfg.png"
          />
          <Typography className="title" variant="body1">
            Crea tu propio evento
          </Typography>
        </div>
      </div>
      <ListItems />
      <div className="download-title-container">
        <Typography className="download-title" variant="h3">
          ¡Comenza a disfrutar, descarga la App!
        </Typography>
      </div>
      <div className="download-app-container">
        <GooglePlayButton />
      </div>
      <div className="footer-container">
        <Footer />
        <Social />
      </div>
    </ThemeProvider>
  );
}
