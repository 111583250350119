export interface Item {
	id: string;
	title: string;
    image: string;
    description: string;
}

export const items: Item[] = [
    {
        id: "1",
        title: "Explora Eventos y Encuentros Únicos",
        image: "https://res.cloudinary.com/dw6c4dado/image/upload/v1712675589/encuentros-app/landing-thunder/items/search-svgrepo-com_tnwab5.svg",
        description: "Desde fiestas temáticas y cenas grupales hasta actividades al aire libre y aventuras de fin de semana, nuestra aplicación te ofrece una diversidad de opciones para que elijas según tus intereses y preferencias."
    },
    {
        id: "2",
        title: "Encuentra Personas con Tus Mismos Intereses",
        image: "https://res.cloudinary.com/dw6c4dado/image/upload/v1712676036/encuentros-app/landing-thunder/items/people-who-support-svgrepo-com_yhzr7w.svg",
        description: "Conoce a personas que comparten tus gustos y aficiones. Nuestra aplicación utiliza algoritmos inteligentes para sugerirte eventos y personas que tienen intereses similares, facilitando así la conexión con quienes comparten tus pasiones."
    },
    {
        id: "3",
        title: "Experiencias Inolvidables",
        image: "https://res.cloudinary.com/dw6c4dado/image/upload/v1712676239/encuentros-app/landing-thunder/items/confetti-svgrepo-com_zmzwfp.svg",
        description: "Vive momentos únicos y experiencias inolvidables junto a nuevas amistades. Ya sea que estés buscando ampliar tu círculo social, encontrar compañeros de viaje o simplemente pasar un buen rato, nuestra aplicación te ofrece oportunidades para hacerlo."
    },
    {
        id: "4",
        title: "Conéctate de Manera Segura y Confiable",
        image: "https://res.cloudinary.com/dw6c4dado/image/upload/v1712676282/encuentros-app/landing-thunder/items/security-open-access-unlock-svgrepo-com_z66hzh.svg",
        description: "Nuestra plataforma prioritiza la seguridad y la privacidad de nuestros usuarios. Con características de verificación de perfiles y opciones de control de privacidad, puedes sentirte seguro mientras exploras y te conectas con otros."
    },
    {
        id: "5",
        title: "Descarga Gratuita y Fácil de Usar",
        image: "https://res.cloudinary.com/dw6c4dado/image/upload/v1712676324/encuentros-app/landing-thunder/items/download-svgrepo-com_ptbtbq.svg",
        description: " ¡Descargar nuestra aplicación es rápido y sencillo! Está disponible en todas las plataformas móviles y es completamente gratuita. Regístrate en minutos y comienza a explorar un mundo de eventos y encuentros casuales."
    }
]