import React from "react";
import './Social.css';

const Social = () => {
    return(
        <div className="container-social">
            <img width="50px" src="https://res.cloudinary.com/dw6c4dado/image/upload/v1712679767/encuentros-app/landing-thunder/instagram-1-svgrepo-com_n7jmmh.svg" />
        </div>
    );
}

export default Social;