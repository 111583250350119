import React from "react";
import { Button, makeStyles } from "@mui/material";

const GooglePlayButton = () => {
  return (
    <Button href="https://play.google.com/store" target="_blank">
      <img
        src="https://res.cloudinary.com/dw6c4dado/image/upload/v1712676533/encuentros-app/landing-thunder/en_badge_web_generic_wbfm7v.png"
        alt="Google Play Store"
        width="300px"
      />
    </Button>
  );
};

export default GooglePlayButton;
